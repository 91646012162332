<template>
  <v-row class="mb-16">
    <v-col
      cols="2"
      class="pr-1"
    >
      <label>
        Кол-во мест
        <input-numeric
          ref="cargo_place_quantity"
          v-model="item.cargo_place_quantity"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :fraction="0"
        />
      </label>
    </v-col>
    <v-col
      cols="2"
      class="px-2"
    >
      <label>
        Вес брутто (кг)
        <input-numeric
          ref="gross_weight"
          v-model="item.gross_weight"
          :fraction="3"
          outlined
          dense
          hide-details="auto"
          background-color="white"
        />
      </label>
    </v-col>
    <v-col
      cols="2"
      class="px-2"
    >
      <label>
        Вес нетто (кг)
        <input-numeric
          ref="net_weight"
          v-model="item.net_weight"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :fraction="3"
        />
      </label>
    </v-col>
    <v-col
      v-if="!isOldStructure"
      cols="2"
      class="px-2"
    >
      <label>
        Код
        <field-menu>
          <v-list-item @click="copyToAll('package_info_code', item.package_info_code )">
            Применить ко всем товарам
          </v-list-item>
          <v-list-item @click="copy('package_info_code', item.package_info_code, 'apply')">
            Применить к выбранным товарам
          </v-list-item>
        </field-menu>
      </label>
      <v-autocomplete
        ref="package_info_code"
        v-model="item.package_info_code"
        auto-select-first
        item-text="text"
        item-value="value"
        :items="packageInfoCodes"
        outlined
        dense
        hide-details="auto"
        background-color="white"
      />
    </v-col>
    <v-col
      v-if="isOldStructure"
      offset="1"
      cols="3"
      class="px-2"
    >
      <label>
        Вид упаковки
        <field-menu>
          <v-list>
            <v-list-item @click="copyToAll('package_type_code', item.package_type_code )">
              Применить ко всем товарам
            </v-list-item>
            <v-list-item @click="copy('package_type_code', item.package_type_code, 'apply')">
              Применить к выбранным товарам
            </v-list-item>
          </v-list>
        </field-menu>
      </label>
      <v-autocomplete
        ref="package_type_code"
        v-model="item.package_type_code"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :items="packages"
        item-text="text"
        item-value="code"
        :filter="filterBySearchField"
      />
    </v-col>
    <v-col
      v-if="isOldStructure"
      cols="2"
      class="px-2"
    >
      <label>
        Кол-во упак.
        <input-numeric
          ref="package_quantity"
          v-model="item.package_quantity"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :fraction="0"
        />
      </label>
    </v-col>
    <v-col
      v-if="!item.package_types_json.length && !isOldStructure && item.package_info_code === 1"
      cols="12"
    >
      <v-btn
        dense
        class="elevation-0"
        @click="addPackage"
      >
        <v-icon>mdi-plus</v-icon> Добавить упаковку
      </v-btn>
    </v-col>
    <v-row
      v-show="item.package_types_json.length"
      class="mx-0"
    >
      <package
        v-for="(item, index) in item.package_types_json"
        :key="`package-${index}`"
        :index="index"
        :item="item"
        :packages="packages"
        @add="addPackage"
        @delete="deletePackage"
        @change="onPackageChange"
        @copy="onPackageCopy"
      />
    </v-row>
    <v-col
      v-if="!item.containers_json.length"
      cols="12"
    >
      <v-btn
        dense
        class="elevation-0 mt-4"
        @click="addContainer"
      >
        <v-icon>mdi-plus</v-icon> Добавить контейнер
      </v-btn>
    </v-col>
    <v-row
      v-show="item.containers_json.length"
      class="mx-0"
    >
      <container
        v-for="(item, containerIndex) in item.containers_json"
        :key="`container-${containerIndex}`"
        :index="containerIndex"
        :item="item"
        @add="addContainer"
        @delete="deleteContainer"
        @change="onContainerChange"
        @copy="onContainerCopy"
      />
    </v-row>
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import Container from "./container.vue";
import Package from "./package.vue";
import cloneDeep from "lodash.clonedeep";
import { updateGoodParameters } from "@/events/statistics";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import InputNumeric from "@/components/ui/input-numeric.vue";
import FieldMenu from "@/components/documents/field-menu.vue";

export default {
  components: {InputNumeric, Container, Package, FieldMenu },
  mixins: [filterBySearchFieldMixin, HighlightFieldWareSector, copyFieldMixin],
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      required: true,
      type: Object,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    item: {
      id: "",
      cargo_place_quantity: "",
      package_info_code: "",
      package_quantity: "",
      package_type_code: "",
      gross_weight: "",
      net_weight:"",
      containers_json: [],
      package_types_json: [],
    },
    packageInfoCodes:[
      {
        text:'0 - без упаковки',
        value:0
      },
      {
        text:'1 - с упаковкой',
        value:1
      },
      {
        text:'2 - без упаковки, в оборудованных емкостях транспортного средства',
        value:2
      },
    ],
  }),
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      packages: "catalogs/getNsiPackageTypes",
      shipmentIndex:'transit/getShipmentIndex',
      isOldStructure: "transit/getIsOldStructure",
    }),
    selectedWares(){
      return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    }
  },
  watch: {
    selectedWares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
    "item.package_info_code"() {
      this.item.package_types_json = [];
    },
  },
  created() {
    updateGoodParameters.subscribe(this.onParamsChange);
  },
  beforeDestroy() {
    updateGoodParameters.unsubscribe();
  },
  methods: {
    onPackageCopy(type){
      if(type === 'all'){
        this.copyToAll('package_types_json', this.item.package_types_json)
      }
      if(type === 'selected'){
        this.copy('package_types_json', this.item.package_types_json, 'apply')
      }
    },
    onPackageChange({ index, value }) {
      this.item.package_types_json.splice(index, 1, value);
      this.readyToUpdate();
    },
    addPackage() {
      const value = { package_type_code: "", package_quantity: "" };
      this.item.package_types_json.push(value);
      this.readyToUpdate();
    },
    deletePackage(index) {
      this.item.package_types_json.splice(index, 1);
      this.readyToUpdate();
    },
    onContainerCopy(type){
      if(type === 'all'){
        this.copyToAll('containers_json', this.item.containers_json)
      }
      if(type === 'selected'){
        this.copy('containers_json', this.item.containers_json, 'apply')
      }
    },
    onParamsChange({ id, payload }) {
      if (id === this.item.id) {
        const { gross_weight, net_weight } = payload;
        this.item.gross_weight = gross_weight;
        this.item.net_weight = net_weight
        this.readyToUpdate();
      }
    },
    onContainerChange({ index, value }) {
      this.item.containers_json.splice(index, 1, value);
      this.readyToUpdate();
    },
    addContainer() {
      const value = { number: "" };
      this.item.containers_json.push(value);
      this.readyToUpdate();
    },
    deleteContainer(index) {
      this.item.containers_json.splice(index, 1);
      this.readyToUpdate();
    },
    updateEditableGoodsItem() {
      this.$emit("update:info", this.item);
    },
    setFields() {
      const ware = cloneDeep(this.selectedWares[this.index]);
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
    },
    readyToUpdate() {
      this.$emit("update:visited", true);
      this.$emit("update:has-changes", true);
    },
  },
};
</script>

<style scoped>
th {
  font-size: 10px !important;
}
td {
  font-size: 12px !important;
}
</style>
