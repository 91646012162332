<template>
  <v-col
    cols="6"
    class="d-flex pb-0"
  >
    <v-col
      cols="5"
      class="pa-0 mr-2"
    >
      <label>
        Вид упаковки
        <field-menu>
          <v-list>
            <v-list-item
              @click="$emit('copy', 'all')"
            >
              Применить ко всем товарам
            </v-list-item>
            <v-list-item
              @click="$emit('copy', 'selected')"
            >
              Применить к выбранным товарам
            </v-list-item>
          </v-list>
        </field-menu>
      </label>
      <v-autocomplete
        ref="package_type_code"
        v-model="item.package_type_code"
        auto-select-first
        outlined
        dense
        hide-details="auto"
        background-color="white"
        :items="packages"
        item-text="text"
        item-value="code"
        :filter="filterBySearchField"
      />
    </v-col>
    <v-col
      cols="5"
      class="pa-0"
    >
      <label>
        Кол-во упак.
        <input-numeric
          ref="package_quantity"
          v-model="item.package_quantity"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          :fraction="0"
        />
      </label>
    </v-col>
    
    <v-col
      cols="2"
      class="py-0"
      align-self="center"
    >
      <div class="d-flex align-center pt-5">
        <v-icon
          small
          @click="remove"
        >
          <!-- @click="deletePackage(idx)" -->
          mdi-delete
        </v-icon>
        <v-icon
          small
          @click="add"
        >
          <!-- @click="addPackage" -->
          mdi-plus
        </v-icon>
      </div>
    </v-col>
  </v-col>
</template>

<script>
import InputNumeric from "@/components/ui/input-numeric.vue";
import FieldMenu from "@/components/documents/field-menu.vue";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";

export default {
  components: { InputNumeric, FieldMenu },
  mixins: [filterBySearchFieldMixin],
  props: {
    index: {
      required: true,
      type: Number,
    },
    item: {
      required: true,
      type: Object,
    },
    packages: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    package_type_code: "",
    package_quantity: "",
  }),
  created() {
    this.number = this.item.number;
  },
  methods: {
    add() {
      this.$emit("add");
    },
    remove() {
      this.$emit("delete", this.index);
    },
    changed(number) {
      const value = { number };
      this.$emit("change", { index: this.index, value });
    },
  },
};
</script>
