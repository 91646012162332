<template>
  <v-container
    :id="block_id"
    v-click-outside="triggerOnFocus"
    fluid
  >
    <v-col cols="12">
      <v-row
        class="statistics-box pb-4"
        :class="{'blocked-box' : disabledView}"
      >
        <v-col
          cols="11"
          class="d-flex align-center"
        >
          <span class="font-weight-bold">
            53 Орган назначения
          </span>
        </v-col>
        <v-col
          cols="12"
          class="pb-0"
        >
          <v-row>
            <v-col cols="4">
              <label>
                Код
                <v-menu offset-x>
                  <template #activator="{ on, attrs }">
                    <v-icon
                      class="mx-1"
                      tabindex="-1"
                      small
                      dense
                      v-bind="attrs"
                      v-on="on"
                    >mdi-tune</v-icon>
                  </template>
                  <v-list>
                    <v-list-item @click="onManualSwitch('manualCode', 'destination_customs_code')">{{ manualCode ? 'Отключить': 'Включить' }} ручное заполнение</v-list-item>
                  </v-list>
                </v-menu>
              </label>
              <v-text-field
                v-show="manualCode"
                ref="destination_customs_code"
                v-model="destination_customs_json.destination_customs_code"
                class="mr-1"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
              <v-autocomplete
                v-show="!manualCode"
                ref="destination_customs_code"
                v-model="destination_customs_json.destination_customs_code"
                auto-select-first
                outlined
                dense
                hide-details="auto"
                background-color="white"
                class="mr-1"
                item-text="text"
                item-value="code"
                :items="customsPoints"
                :filter="filterBySearchField"
              />
            </v-col>
            <v-col
              v-if="isOldStructure"
              cols="6"
              offset="2"
            >
              <v-textarea
                :value="address"
                readonly
                rows="2"
                outlined
                dense
                hide-details="auto"
                background-color="grey lighten-2"
              />
            </v-col>


            <v-col
              cols="12"
              class="d-flex align-center"
            >
              <v-row>
                <v-col cols="6">
                  <span>Дополнительно</span>
                  <v-row>
                    <v-col>
                      <label>
                        Номер ЗТК
                        <v-menu offset-x>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              class="mx-1"
                              tabindex="-1"
                              small
                              dense
                              v-bind="attrs"
                              v-on="on"
                            >mdi-tune</v-icon>
                          </template>
                          <v-list>
                            <v-list-item @click="onManualSwitch('manual', 'destination_customs_id')">{{ manual ? 'Отключить': 'Включить' }} ручное заполнение</v-list-item>
                          </v-list>
                        </v-menu>
                      </label>
                      <v-text-field
                        v-show="manual"
                        ref="destination_customs_id__custom"
                        v-model="destination_customs_json.destination_customs_id"
                        class="mr-1"
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                      />
                      <v-autocomplete
                        v-show="!manual"
                        ref="destination_customs_id"
                        v-model="destination_customs_json.destination_customs_id"
                        :items="storages"
                        auto-select-first
                        item-text="text"
                        item-value="licenseNumber"
                        class="mr-1"
                        outlined
                        dense
                        hide-details="auto"
                        background-color="white"
                        :filter="filterBySearchField"
                      />
                    </v-col>
                    <v-col>
                      <label>
                        Ж/д станция
                        <v-autocomplete
                          ref="destination_customs_station"
                          v-model="destination_customs_json.destination_customs_station"
                          auto-select-first
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          class="mr-1"
                          item-text="search"
                          item-value="code"
                          :items="railwayStations"
                        />
                      </label>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <span>Сведения об уполномоченном экономическом  операторе </span>
                  <v-row>
                    <v-col cols="4">
                      <label>
                        Страна
                        <v-menu offset-x>
                          <template #activator="{ on, attrs }">
                            <v-icon
                              class="mx-1"
                              tabindex="-1"
                              small
                              dense
                              v-bind="attrs"
                              v-on="on"
                            >mdi-tune</v-icon>
                          </template>
                          <v-list>
                            <v-list-item> Поиск в справочнике </v-list-item>
                          </v-list>
                        </v-menu>
                        <v-autocomplete
                          ref="destination_customs_aeo_country_letter"
                          v-model="
                            destination_customs_json.destination_customs_aeo_country_letter
                          "
                          auto-select-first
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          :items="countries"
                          item-text="text"
                          item-value="letterCode"
                          class="mr-1"
                          :filter="filterBySearchField"
                        />
                      </label>
                    </v-col>
                    <v-col cols="5">
                      <label>
                        Номер по реестру
                        <v-autocomplete
                          ref="destination_customs_aeo_reg_number"
                          v-model="destination_customs_json.destination_customs_aeo_reg_number"
                          auto-select-first
                          outlined
                          dense
                          hide-details="auto"
                          background-color="white"
                          class="mr-1"
                          :items="aeos"
                          item-value="licenseNumber"
                          item-text="text"
                        />
                      </label>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
          <v-row v-if="!isOldStructure && destination_customs_json.destination_customs_aeo_reg_number">
            <v-col cols="3">
              <label>Область/Район</label>
              <v-text-field
                v-model="destination_customs_json.region"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col cols="3">
              <label>Город/Деревня</label>
              <v-text-field
                v-model="destination_customs_json.city"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col cols="2">
              <label>Улица</label>
              <v-text-field
                v-model="destination_customs_json.street_house"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col cols="1">
              <label>Дом</label>
              <v-text-field
                v-model="destination_customs_json.house"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
            <v-col cols="2">
              <label>Номер помещ.</label>
              <v-text-field
                v-model="destination_customs_json.room"
                outlined
                dense
                hide-details="auto"
                background-color="white"
              />
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import {getCountryNameByCode, getCustomsNameByCode} from "@/helpers/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import { highlightField } from "@/helpers/control"
import {destination_customs as onDestinationCustoms} from "@/events/statistics/control";
import customFieldCheckerNull from '@/mixins/custom-field-checker'
export default {
  mixins: [filterBySearchFieldMixin, blockAutoUpdate, customFieldCheckerNull],
  data: () => ({
    block_id: "transit-destination-customs",
    manualCode:false,
    manual:false,
    show: false,
    railwayStations: [],
    destination_customs: {
      ware_shipment_id: null,
    },
    destination_customs_json:{
      destination_customs_code: null,
      destination_customs_id: null,
      destination_customs_station: null,
      destination_customs_aeo_reg_number: null,
      destination_customs_aeo_country_letter: null,
      region: null,
      city: null,
      street_house: null,
      house: null,
      room: null,
    }
  }),
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      countries: "catalogs/getNsiCountries",
      customsPoints: "catalogs/getNsiCustomsPoints",
      aeos: "transit/getNsiAeos",
      shipmentIndex:'transit/getShipmentIndex',
      disabledView:'transit/getVisibility',
      storages:"catalogs/getNsiCustomsStorages",
      isOldStructure: "transit/getIsOldStructure",
    }),
    address(){
      const item = this.customsPoints.find(i => i.code === this.destination_customs_json.destination_customs_code)
      return item?.address ?? ""
    },
    transitProcedure(){
      return this.selected.type_declaration.transit_procedure
    }
  },
  watch: {
    selected: {
      handler() {
        this.setFields();
      },
      deep: true,
      immediate:true
    },
    shipmentIndex(){
      this.setFields()
    },
    item: {
      handler() {
        if (this.visited) this.updateEditableGoodsItem();
      },
      deep: true,
    },
  },
  created() {
    onDestinationCustoms.subscribe(this.highlightField)
    this.fetchRailwayStations();
  },
  methods: {
    setManualDefault(params, field, directory, param){
      this[params] = this.destination_customs_json[field] ? !this[directory].some(i => i[param] === this.destination_customs_json[field]): false
    },
    readyToUpdate(){
      this.hasChanges = true
      this.fields_been_visited = true
    },
    onManualSwitch(params, field){
      this[params]= !this[params]
      this.destination_customs_json[field] = null
      this.readyToUpdate()
    },
    highlightField,
    fetchRailwayStations() {
      this.$store
        .dispatch("catalogs/fetchNsiRailwayStations")
        .then((res) => {
          this.railwayStations = res.data.list;
          this.railwayStations.forEach((item) => {
            item.search = `${item.code}-${item.name}`;
          });
        })
        .catch(() => {
          this.$snackbar({
            text: "Возникла ошибка при загрузке справочника",
            color: "red",
            top: false,
            right: false,
          });
        });
    },
    setFields() {
      const {destination_customs} = this.selected.ware_shipments[this.shipmentIndex];
      const {destination_customs_json} = destination_customs;

      Object.keys(this.destination_customs).forEach((key) => {
        if (key in destination_customs) {
          this.destination_customs[key] = destination_customs[key];
        }
      });

      Object.keys(this.destination_customs_json).forEach((key) => {
        if (key in destination_customs_json) {
          this.destination_customs_json[key] = destination_customs_json[key];
        }
      });

      this.setManualDefault('manual', 'destination_customs_id', 'storages', 'licenseNumber');
      this.setManualDefault('manualCode', 'destination_customs_code', 'customsPoints', 'code');
    },
    uploadData() {
      const destination_customs = this.convertEmptyStringsToNull({
        ...this.destination_customs,
        destination_customs_json: {
          ...this.destination_customs_json,
          destination_customs_name:
              getCustomsNameByCode(
                this.customsPoints,
                this.destination_customs_json.destination_customs_code
              ),
          destination_customs_aeo_country_name: getCountryNameByCode(
            this.countries,
            this.destination_customs_json.destination_customs_aeo_country_letter
          ),
        },
      })

      return this.$store.dispatch("transit/uploadShipmentBlockData", {
        name: "destination_customs",
        value: destination_customs,
      });
    },
  },
};
</script>
