<template>
  <v-row>
    <v-col cols="12">
      <span class="font-weight-bold mb-4">
        Сведения о товаре
        <v-icon
          dense
          class="mx-1"
        >mdi-menu-open</v-icon>
      </span>
    </v-col>
    <v-col cols="2">
      <label>
        33 Код товара
        <v-menu offset-x>
          <template #activator="{ on, attrs }">
            <v-icon
              tabindex="-1"
              class="mx-1"
              small
              dense
              v-bind="attrs"
              v-on="on"
            >
              mdi-tune
            </v-icon>
          </template>
          <v-list>
            <v-list-item @click="showTNVED">
              Поиск в справочнике
            </v-list-item>
            <v-list-item @click="copyToAll('tn_ved', item.tn_ved )">
              Применить ко всем товарам
            </v-list-item>
            <v-list-item @click="copy('tn_ved', item.tn_ved, 'apply')">
              Применить к выбранным товарам
            </v-list-item>
          </v-list>
        </v-menu>
      </label>

      <div class="d-flex">
        <v-text-field
          ref="tn_ved"
          v-model="item.tn_ved"
          v-mask="'##########'"
          class="tn_ved mr-2"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="tnvedChanged"
          @keypress.enter="showTNVED"
        />
        <v-btn
          :class="{'teal lighten-2': tab === 0}"
          max-width="36px"
          min-width="36px"
          text
          @click="toggleTreeView"
        >
          <v-icon>
            mdi-file-tree-outline
          </v-icon>
        </v-btn>
        <v-btn
          max-width="36px"
          min-width="36px"
          text
          @click="showCodeInfo(item.tn_ved)"
        >
          <v-icon>mdi-information</v-icon>
        </v-btn>
      </div>
    </v-col>
    <v-col
      v-if="isOldStructure"
      cols="1"
      class="pr-0"
    >
      <label>
        Код
        <v-icon small>mdi-information-variant</v-icon>
        <v-text-field
          ref="license_type_code"
          v-model="item.license_type_code"
          v-mask="'####'"
          background-color="white"
          outlined
          dense
          hide-details="true"
        />
      </label>
    </v-col>
    <v-col
      v-if="isOldStructure"
      cols="1"
    >
      <label>
        ПП
        <field-menu>
          <v-list-item @click="copyToAll('traceability', item.traceability )">
            Применить ко всем товарам
          </v-list-item>
          <v-list-item @click="copy('traceability', item.traceability, 'apply')">
            Применить к выбранным товарам
          </v-list-item>
        </field-menu>
      </label>


      <v-autocomplete
        ref="traceability"
        v-model="item.traceability"
        :items="[
          { text: '0', value: false },
          { text: '1', value: true },
        ]"
        item-text="text"
        item-value="value"
        auto-select-first
        background-color="white"
        dense
        outlined
        hide-details="true"
      />
    </v-col>
    <div
      class="bordered d-flex justify-center pa-2 py-2 mr-6 ml-2"
      style="width:10%;"
    >
      <div
        v-for="(checkbox) of checkboxes"
        :key="checkbox.key"
      >
        <v-menu offset-x>
          <template #activator="{ on, attrs }">
            <label
              v-bind="attrs"
              class="font-weight-bold teal--text cursor-pointer d-block px-1"
              v-on="on"
            >{{ checkbox.label }}</label>
          </template>
          <v-list>
            <v-list-item @click="copyToAll(checkbox.key, item[checkbox.key])">
              Применить ко всем товарам ({{ checkbox.label }})
            </v-list-item>
            <v-list-item @click="copy(checkbox.key, item[checkbox.key], 'apply')">
              Применить к выбранным товарам ({{ checkbox.label }})
            </v-list-item>
          </v-list>
        </v-menu>
        <v-checkbox
          v-model="item[checkbox.key]"
          dense
          class="mx-0"
          hide-details
          color="black"
          @change="readyToUpdate"
        />
      </div>
    </div>

    <ware-dynamic-tnved-tabs
      v-model="tab"
      class="py-0"
      :tabs="tabs"
      :code="code"
    />
  </v-row>
</template>

<script>
import { mapGetters } from "vuex";
import {
  showCatalog,
  tnvedCodeSelected,
  tnvedCodeChanged,
} from "@/events/statistics";
import cloneDeep from "lodash.clonedeep";
import HighlightFieldWareSector from "@/mixins/control/highlight-field-ware-sector";
import {openGoodsModal} from "@/events/common";
import copyFieldMixin from "@/mixins/copy-field.mixin";
import {showCodeInfo} from "@/helpers/tnved";
import WareDynamicTnvedTabs from "@/components/documents/ware/ware-dynamic-tnved-tabs.vue";
import FieldMenu from "@/components/documents/field-menu.vue";
import {readyToUpdateInjector} from "@/helpers/injectors";

export default {
  components: {FieldMenu, WareDynamicTnvedTabs},
  mixins:[HighlightFieldWareSector, copyFieldMixin],
  inject:{
    readyToUpdate:{
      from:readyToUpdateInjector
    },
  },
  props: {
    index: {
      required: true,
      type: Number,
    },
    info: {
      type: Object,
      required: true,
    },
    visited: {
      required: true,
      type: Boolean,
    },
    hasChanges: {
      required: true,
      type: Boolean,
    },
  },
  data: () => ({
    tab: null,
    selectedTab: null,
    tabs: [
      {
        title: "Показать структуру",
        id: 1,
        component: () => import("@/components/tnved/structure.vue"),
      },
    ],
    item: {
      id: "",
      tn_ved: "",
      license_type_code: "",
      traceability: "",
      under_ban: "",
      component: "",
    },
    code: "",
  }),
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      shipmentIndex:'transit/getShipmentIndex',
      isOldStructure: "transit/getIsOldStructure",
    }),
    openGoodsModal() {
      return openGoodsModal
    },
    selectedWares(){
      return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    },
    checkboxes() {
      return [
        {
          label:"С",
          key:"under_ban"
        },
        {
          label:"К",
          key:"component"
        }]
    },
  },
  watch: {
    selectedWares: {
      handler() {
        this.setFields();
      },
      immediate: true,
      deep: true,
    },
    item: {
      handler() {
        // Пропс visited обвновляется позже чем вызывается обработчик,
        // поэтому нужно свойство внутри компонента
        if (this.visited) {
          this.updateEditableGoodsItem();
        }
      },
      deep: true,
    },
  },
  created() {
    tnvedCodeSelected.subscribe(this.checkAndUpdateTNVED);
  },
  beforeDestroy() {
    tnvedCodeSelected.unsubscribe();
  },
  methods: {
    showCodeInfo,
    toggleTreeView(){
      this.tab = this.tab === 0 ? null : 0
    },
    checkAndUpdateTNVED({ code, id }) {
      // Событие при выборе кода из справочника
      if (id === this.item.id) {
        this.item.tn_ved = code;
        this.code = code;
        tnvedCodeChanged.trigger({ id: this.item.id, code: this.code });
        this.$emit("update:visited", true);
        this.$emit("update:has-changes", true);
      }
    },
    tnvedChanged() {
      // Событие при "выходе" из инпута
      this.code = this.item.tn_ved;
      tnvedCodeChanged.trigger({ id: this.item.id, code: this.code });
    },
    updateEditableGoodsItem() {
      this.$emit("update:info", this.item);
      if (this.tn_ved_selected === true) this.tn_ved_selected = false;
    },
    showTNVED() {
      showCatalog.trigger({
        type: "tnved",
        id: this.item.id,
        search: this.item.tn_ved,
      });
    },
    setFields() {
      const ware = cloneDeep(this.selectedWares[this.index]);
      Object.keys(this.item).forEach((key) => {
        if (key in ware) {
          this.item[key] = ware[key];
        }
      });
      this.code = this.info.tn_ved;
    },
  },
};
</script>
<style scoped>
.tn_ved{
  width: 150px;
}
.show-tnved {
  color: rgba(0, 0, 0, 0.54);
  font-size: 14px;
}
.bordered {
  /* background: #fff; */
  border: 1px #6E6E6E solid;
  border-radius: 6px;
  margin-right: auto;
  column-gap: 20px;
}
</style>
