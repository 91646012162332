<template>
  <v-row :id="block_id">
    <v-col
      cols="1"
      class="pl-3 pr-0 pb-0"
    >
      <v-checkbox
        tabindex="-1"
        dense
        class="pa-0 mt-8"
        hide-details
        color="#5CB7B1"
        :value="selectedDocs.includes(item.id)"
        @change="updateSelected"
      />
    </v-col>
    <v-col
      cols="2"
      class="px-1 pb-0"
    >
      <label>
        Код вида док.
        <v-autocomplete
          ref="doc_code"
          :value="item.doc_code"
          auto-select-first
          item-text="code"
          :filter="filterBySearchField"
          item-value="code"
          :items="doc_types"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @change="(value) => updateDocument('doc_code', value)"
        />
      </label>
    </v-col>
    <v-col
      v-if="item.doc_code !== '09026'"
      class="px-1 pb-0"
    >
      <label>
        Рег. номер
        <v-text-field
          v-show="item.doc_code === '09013'"
          ref="doc_number__custom"
          :value="item.doc_number"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @input="onMaskedDocNumberInput"
        />
        <v-text-field
          v-show="item.doc_code !== '09013'"
          ref="doc_number"
          :value="item.doc_number"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @input="(value) => updateDocument('doc_number', value)"
        />
      </label>
    </v-col>
    <v-col
      v-if="isOldStructure"
      cols="2"
      class="px-1 pb-0"
    >
      <label>
        № тов.
        <v-text-field
          ref="ware_number_from_dt"
          :value="item.ware_number_from_dt"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @input="(value) => updateDocument('ware_number_from_dt', value)"
        />
      </label>
    </v-col>
    <v-col
      v-if="!isOldStructure && item.doc_code === '09013'"
      cols="3"
      class="px-1 pb-0"
    >
      <label>
        Книжка МДП
        <v-text-field
          ref="tir_serial_number"
          v-mask="'SS########'"
          :value="item.tir_serial_number"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @input="(value) => (item.tir_serial_number = value.toLocaleUpperCase())"
          @change="(value) => updateDocument('tir_serial_number', value)"
        />
      </label>
    </v-col>
    <v-col
      v-if="!isOldStructure && item.doc_code === '10044'"
      cols="3"
      class="px-1 pb-0"
    >
      <label>
        Код процедуры
        <v-autocomplete
          ref="customs_procedure_code"
          v-model="item.customs_procedure_code"
          :items="customsProcedures"
          item-text="text"
          item-value="code"
          class="mr-1 p-0"
          auto-select-first
          outlined
          hide-details="auto"
          dense
          background-color="white"
          @change="(value) => updateDocument('customs_procedure_code', value)"
        />
      </label>
    </v-col>
    <v-col
      v-if="!isOldStructure && item.doc_code === '09026'"
      class="px-1 pb-0"
    >
      <label>
        № документа 
        <v-text-field
          ref="doc_id"
          :value="item.doc_id"
          class="mr-1"
          outlined
          dense
          hide-details="auto"
          background-color="white"
          @input="(value) => updateDocument('doc_id', value)"
        />
      </label>
    </v-col>
    <v-col
      v-if="!isOldStructure && item.doc_code === '09026'"
      class="px-1 pb-0"
      cols="3"
    >
      <label>Дата</label>
      <custom-date-picker
        ref="doc_creation_date"
        v-model="item.doc_creation_date"
        :visited.sync="fields_been_visited"
        :has-changes.sync="hasChanges"
        background-color="white"
        @change="(value) => updateDocument('doc_creation_date', value)"
      />
    </v-col>
    <v-col
      cols="1"
      class="pt-10 px-0 pb-0"
    >
      <div class="d-flex">
        <!-- <v-icon
          small
          class="mr-1"
          @click="$emit('delete', item.id)"
        >
          mdi-delete
        </v-icon> -->
        <v-icon
          v-if="documents.length - 1 === index"
          @click="$emit('add')"
        >
          mdi-plus
        </v-icon>
      </div>
    </v-col>
  </v-row>
</template>
<script>
import { mapGetters } from "vuex";
import { filterBySearchFieldMixin } from "@/mixins/catalogs";
import blockAutoUpdate from "@/mixins/block-auto-update.mixin";
import highlightFieldsChild from "@/mixins/control/highlight-fields-child";
import CustomDatePicker from "@/components/shared/custom-date-picker";

export default {
  components: { CustomDatePicker },
  mixins: [filterBySearchFieldMixin, highlightFieldsChild, blockAutoUpdate],
  props: {
    value: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      required: true,
    },
    wareIndex: {
      type: Number,
      required: true,
    },
    documents: {
      type: Array,
      required: true,
    },
    selectedDocs: {
      required: true,
      type: Array,
    },
  },
  data: () => ({
    block_id: null,
    item: {
      id: null,
      ware_id: null,
      doc_code: null,
      doc_number: null,
      ware_number_from_dt: null,
      sort_index: null,
      tir_serial_number: null,
      customs_procedure_code: null,
      doc_id: null,
      doc_creation_date: null,
    },
  }),
  computed: {
    ...mapGetters({
      selected:"transit/getSelected",
      doc_types: "catalogs/getNsiTypesOfDocument",
      shipmentIndex:'transit/getShipmentIndex',
      isOldStructure: "transit/getIsOldStructure",
      customsProcedures: "catalogs/getNsiCustomsProcedures",
    }),
    selectedWares() {
      return this.selected.ware_shipments[this.shipmentIndex]?.wares ?? []
    },
  },
  watch: {
    selectedWares: {
      handler() {
        this.block_id = `preceding-doc-${this.index}`;
        this.setFields();
      },
      deep: true,
      immediate: true,
    },
    "item.doc_code"(newVal) {
      if(newVal === '09026') this.item.doc_number = null;
      else this.item.doc_id = null;
    },
  },
  methods: {
    updateSelected() {
      this.$emit("select", this.item.id);
    },
    setFields() {
      Object.keys(this.item).forEach((key) => {
        if (key in this.value) {
          this.item[key] = this.value[key];
        }
      });
    },
    onMaskedDocNumberInput(value) {
      this.useDocumentMask(value);
      this.updateDocument("doc_number", this.item.doc_number);
    },
    useDocumentMask(value) {
      // mask = ########/######/#######
      const MAX_LENGTH = 23;
      const FIRST_PATH_WITH_SLASH = 9;
      const SECOND_PART_WITH_SLASH = 16;
      if (this.item.doc_code === "09013") {
        const symbols = value.split("");
        const errors = symbols.filter(
          (i) => i !== "/" && Number.isNaN(Number.parseInt(i))
        );
        if (errors.length || value.length > MAX_LENGTH) {
          const { doc_number } = this.item;
          this.item.doc_number = ""; // Чтобы значение в v-text-field обновилось
          this.$nextTick(() => {
            this.item.doc_number = doc_number;
          });
        } else {
          if (
            value.length > this.item.doc_number?.length &&
            (value.length === FIRST_PATH_WITH_SLASH ||
              value.length === SECOND_PART_WITH_SLASH)
          ) {
            const symbols = value.split("").reverse();
            const [end, ...start] = symbols;
            this.item.doc_number = [...start.reverse(), "/", end].join("");
          } else {
            this.item.doc_number = value;
          }
        }
      } else {
        this.item.doc_number = value;
      }
    },
    updateDocument(key, value) {
      this.item[key] = value;
      this.$emit("update", { index: this.index, value: this.item });
    },
  },
};
</script>
