<template>
  <div
    class="statistics-box pa-4"
    :class="{ 'blocked-box': blocked_visibility }"
  >
    <v-row>
      <v-col
        cols="4"
        class="pa-0"
      >
        <label>
          <!-- <span v-if="isFirst">Фамилия</span> -->
          Фамилия
          <v-text-field
            ref="driver_first_name"
            v-model="driver_item.driver_first_name"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            @change="updatedField"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="pa-0"
      >
        <label>
          Имя
          <v-text-field
            ref="driver_last_name"
            v-model="driver_item.driver_last_name"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            @change="updatedField"
          />
        </label>
      </v-col>
      <v-col
        cols="4"
        class="pa-0"
      >
        <label>
          Отчество
          <v-text-field
            ref="driver_middle_name"
            v-model="driver_item.driver_middle_name"
            class="mr-1"
            outlined
            dense
            hide-details="auto"
            background-color="white"
            @change="updatedField"
          >
            <template
              #append-outer
            >
              <v-icon
                @click="addDriverItem"
              >mdi-plus</v-icon>
              <v-icon
                class="ml-1"
                @click="deleteDriverItem"
              >mdi-delete-outline
              </v-icon>
            </template>
          </v-text-field>
        </label>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import blockVisibility from '@/mixins/block-visibility'

export default {
  mixins: [blockVisibility],
  props: {
    item: {
      required: true,
      type: Object,
    },
    idx: {
      required: true,
      type: Number,
    },
  },
  data: () => ({
    driver_item: {
      driver_first_name: "",
      driver_last_name: "",
      driver_middle_name: "",
    },
  }),
  computed: {
    isFirst() {
      return this.idx === 0;
    },
  },
  watch: {
    item: {
      handler(newVal) {
        for (let key in newVal) {
          if (key in this.driver_item) {
            this.driver_item[key] = newVal[key];
          }
        }
      },
      deep: true,
      immediate: true,
    },
  },
  methods: {
    deleteDriverItem() {
      this.$emit("deleteDriver", this.idx);
    },
    addDriverItem() {
      this.$emit("addDriver");
    },
    updatedField() {
      this.$emit("driversUpdater", {
        driver_item: this.driver_item,
        index: this.idx,
      });
    },
  },
};
</script>
