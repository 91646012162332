<template>
  <div>
    <v-container
      fluid
    >
      <v-row class="mb-1">
        <v-col
          cols="2"
          class="d-flex align-center"
        >
          <span class="font-weight-bold">4 Всего товаров ({{ wares.length }})</span>
          <v-menu
            v-if="!disabledView"
            offset-x
          >
            <template #activator="{ on, attrs }">
              <v-btn
                max-height="32px"
                min-width="32px"
                max-width="32px"
                text
                tabindex="-1"
                v-bind="attrs"
                class="ml-2 elevation-0 wares-menu"
                v-on="on"
              >
                <v-icon>mdi-tune</v-icon>
              </v-btn>
            </template>
            <v-list>
              <v-list-item
                class="wares-menu-sort-id"
                @click="sortGoods('id')"
              >
                Сортировать по порядку
              </v-list-item>
              <v-list-item
                class="wares-menu-sort-tnved"
                @click="sortGoods('code')"
              >
                Сортировать по ТНВЭД
              </v-list-item>
              <v-list-item
                class="wares-menu-sort-tnved"
                @click="openCustomSortingModal"
              >
                Произвольная сортировка
              </v-list-item>
              <v-list-item
                class="wares-menu-group-id"
                @click="groupGoods"
              >
                Группировать товары
              </v-list-item>
              <v-list-item @click="confirmDelete">
                Удалить все товары
              </v-list-item>
              <v-list-item
                @click="deleteSeveralGoods"
              >
                Удалить несколько товаров
              </v-list-item>
            </v-list>
          </v-menu>
          <grouping-goods 
            ref="groupingGoods"
          />
        </v-col>
        <v-col
          v-if="!disabledView"
          cols="2"
        >
          <upload-excel />
        </v-col>
        <v-col cols="2">
          <download-excel />
        </v-col>
        <v-col
          cols="3"
        >
          <download-specification />
        </v-col>
      </v-row>
      <div
        id="transit-wares"
        class="transit-goods-list"
      >
        <goods-item
          v-for="(item, idx) in wares"
          :key="'goods-items-' + item.id"
          ref="goodsItem"
          :item="item"
          :index="idx"
        />
      </div>

      <v-col
        cols="12"
        class="px-0 d-flex justify-space-between align-end"
      >
        <v-btn
          v-if="!disabledView"
          color="#5CB7B1"
          :disabled="inProcess"
          @click="addGoodsItem"
        >
          <v-icon>mdi-plus</v-icon> Добавить товар
        </v-btn>
        <v-btn
          v-if="!disabledView"
          dark
          :disabled="inProcess"
          @click="validatePayments"
        >
          <v-icon>mdi-currency-eur</v-icon>Обеспечение
        </v-btn>
      </v-col>
    </v-container>
    <component
      :is="activeComponent"
      :show.sync="showComponent"
      @perform="onPerform"
    />
    <sanctioned-product-notification />
  </div>
</template>
<script>
import GoodsItem from "./goods-item.vue";
import { mapGetters } from "vuex";
import {validatePayments} from '../controls/payments'
import DownloadExcel from "@/components/documents/download-excel.vue";
import UploadExcel from "@/components/transit/goods/upload-excel.vue";
import {openGoodsModal} from "@/events/common";
import {callFunction} from "@/helpers/common";
import {downloadFile, getFilenameFromContentDisposition} from "@/helpers/documents";
import DownloadSpecification from "@/components/documents/download-specification.vue";
import SanctionedProductNotification from "@/components/documents/warnings/sanctioned-product-notification.vue";
import GroupingGoods from '@/components/transit/goods/grouping-goods.vue'

export default {
  components: {
    SanctionedProductNotification,
    DownloadSpecification,
    UploadExcel, DownloadExcel,
    GoodsItem,
    GroupingGoods
  },
  data:() => ({
    inProcess:false,
    waresModal: false,
    waresParams: null,
    showComponent:false,
    activeComponent:null,
  }),
  computed: {
    ...mapGetters({
      selected: "transit/getSelected",
      shipmentIndex: 'transit/getShipmentIndex',
      disabledView:'transit/getVisibility'
    }),
    wares(){
      return this.selected?.ware_shipments.at(this.shipmentIndex)?.wares ?? []
    },
  },
  methods: {
    validatePayments,
    callFunction,
    onPerform(payload){
      this.callFunction(payload.action)
    },
    confirmDelete(){
      this.activeComponent = () => import('@/components/documents/confirm-delete-wares.vue')
      this.showComponent = true
    },
    deleteAllGoods(){
      this.$store.dispatch("transit/deleteAllGoods")
        .then(() => {
          this.$snackbar({text:"Действие применено", top:false, right: false})
        })
        .catch(() => {
          this.$snackbar({text:'Ошибка', color:'red', top:false, right: false})
        })
    },
    deleteSeveralGoods(){
      openGoodsModal.trigger({action:'delete'})
    },
    openCustomSortingModal(){
      this.activeComponent = () => import('@/components/transit/goods/custom-sorting-modal.vue')
      this.showComponent = true
    },
    addGoodsItem() {
      this.inProcess = true
      this.$store.dispatch("transit/addShipmentNewGoodsItem").then(() => {
        const tabIndexButtons = document.querySelectorAll('.ware-item-toggle')
        tabIndexButtons[tabIndexButtons.length - 1].focus()
        this.inProcess = false
      }).catch(() => {
        this.$snackbar({text:'Не удалось создать товар', color:'red', top:false, right: false})
        this.inProcess = false
      })
    },
    sortGoods(sortBy) {
      this.$store.dispatch("transit/sortGoods", { sortBy });
    },
    downloadTemplate(){
      this.$store.dispatch('transit/loadTemplate').then(res => {
        const {data} = res
        const name = getFilenameFromContentDisposition(res)
        downloadFile({data, name, type:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;'})
      }).catch(() => this.$error())
    },
    groupGoods() {
      this.$refs.groupingGoods.openUserDialog()
    }
  },
};
</script>
